<template>
  <div class="w-48">
    <p class="font-secondary text-primary text-2xl uppercase">Filtrar por:</p>
    <button
      v-show="hasActiveFilters"
      class="border-2 rounded-full border-primary text-primary hover:border-secondary hover:text-secondary flex items-center"
      @click="clearFilters()"
    >
      <span class="mx-2 transform -translate-y-px">Remover Filtros</span>
      <r-icon type="backspace" class="h-6 mx-2" />
    </button>
    <div v-for="[filter, options] in filters" :key="filter">
      <TypeFilter
        v-if="!facetStats.includes(filter)"
        :name="filter"
        :options="options"
      />
      <NumericFilter
        v-else
        :name="filter"
        :options="options"
        :stats="facets_stats[filter]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import NumericFilter from './Filters/Numeric.vue';
import TypeFilter from './Filters/Type.vue';
import {
  hasActiveFilters,
  useFacetsStats,
  clearFilters,
  useFacets,
} from '@/store/search';

export default defineComponent({
  name: 'EquipmentFilters',
  components: {
    TypeFilter,
    NumericFilter,
  },
  setup() {
    const facets = useFacets();
    const facets_stats = useFacetsStats();
    const facetStats = computed(() => Object.keys(facets_stats.value));
    const filters = computed(() => Object.entries(facets.value).filter(([key, value]) => (
      key.match(/specifications/i) && !key.match(/Peso/) && Object.keys(value).length > 1
    )));
    return { clearFilters, facets_stats, facetStats, filters, hasActiveFilters };
  },
});
</script>

<style></style>
