
import { defineComponent } from 'vue';
import {
  usePage,
  numberOfPages,
  changePage,
  filterEquipment,
} from '../store/search';

export default defineComponent({
  setup() {
    const page = usePage();
    return { page, numberOfPages };
  },
  methods: {
    moveToPage(page: number) {
      changePage(page);
      filterEquipment();
    },
  },
});
