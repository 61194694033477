import { readonly, ref, reactive } from 'vue';
import algoliaClient from '@/common/algoliaClient';
import { AlgoliaResponse, FacetFilters, Facets, FacetsStats, NumericFilters } from './models/search';
import { emptyObject } from '@/utils';

const emptyFacetFilter = () => ({categories: [], subcategories: []});
const INDEX = algoliaClient.initIndex('equipments');

const query = ref('');
const algoliaResponse = ref<AlgoliaResponse | null>(null);
const facets = ref<Facets>({});
const facets_stats = ref<FacetsStats>({});
const facetFilters = ref<FacetFilters>(emptyFacetFilter());
const numericFilters = ref<NumericFilters>({});
const page = ref(0);
const searching = ref(false);

export const useQuery = () => readonly(query);
export const useAlgoliaResponse = () => readonly(algoliaResponse);
export const useFacets = () => readonly(facets);
export const useFacetsStats = () => readonly(facets_stats);
export const useFacetFilters = () => readonly(facetFilters);
export const useNumericFilters = () => readonly(numericFilters);
export const usePage = () => readonly(page);
export const useSearching = () => readonly(searching);


const state = reactive({
  query,
  algoliaResponse,
  facets,
  facets_stats,
  facetFilters,
  numericFilters,
  page,
  searching,
});

export function searchEquipment(query: string | null = null) {
  if(query !== null) setQuery(query);

  state.searching = true;
  clearFilters();

  INDEX.search(state.query, {
    page: state.page? state.page : undefined,
    facets: ['*', 'categories', 'specifications.*'],
    ...formattedFilters(),
    hitsPerPage: 12,
  }).then(response => {
    state.algoliaResponse = response;
    state.facets = response.facets || {};
    state.facets_stats = response.facets_stats || {};
    state.page = response.page;
  }).catch(function(error) {
    console.error(error);
  }).finally(() => {
    state.searching = false;
  });
}

export function filterEquipment(query: string|null = null) {
  if(query !== null) setQuery(query);

  state.searching = true;
  INDEX.search(state.query as string, {
    page: state.page? state.page : undefined,
    facets: ['*', 'categories', 'specifications.*'],
    ...formattedFilters(),
    hitsPerPage: 12,
  }).then(response => {
    state.algoliaResponse = response;
    state.page = response.page;
  }).catch(function(error) {
    console.error(error);
  }).finally(() => {
    state.searching = false;
  });
}

export function toggleFacet(facet: any) {
  if (isFacetActive(facet)) {
    removeFacet(facet);
  } else {
    addFacet(facet);
  }
}

export function applyFilter(filter: any) {
  if (state.numericFilters[filter.name]) {
    state.numericFilters[filter.name] = filter.value;
  } else {
    state.numericFilters = {
      ...state.numericFilters,
      [filter.name]: filter.value,
    };
  }
  state.page = 0;
}

export function resetSearch() {
  state.query = '';
  state.algoliaResponse = null;
  state.facets = {};
  state.facets_stats = {};
  state.facetFilters = emptyFacetFilter();
  state.numericFilters = {};
  state.page = 0;
  state.searching = false;
}

export function removeFacet(facet: any) {

  const { categories = [], subcategories = [], ...facets } = state.facetFilters;

  if (facets[facet.name]) {
    facets[facet.name].splice(facets[facet.name].indexOf(facet.option), 1);
  }

  if (subcategories.indexOf(facet.option) >= 0) {
    subcategories.splice(subcategories.indexOf(facet.option), 1);
  }

  if (!state.facets[facet.name].length) delete state.facets[facet.name];

  state.facetFilters = { subcategories, ...facets, categories };
  state.page = 0;
}

export function addFacet(facet: any) {
  const { categories = [], subcategories = [], ...facets } = state.facetFilters;
  if (facets[facet.name]) {
    facets[facet.name].push(facet.option);
  } else {
    facets[facet.name] = [facet.option];
  }
  state.facetFilters = { categories, subcategories, ...facets };
  state.page = 0;
}

export function changePage(page: number) {
  state.page = Math.max(Math.min(page, (state.algoliaResponse?.nbPages || 0) - 1), 0);
}

export function clearFilters() {
  const { categories = [], subcategories = [] } = state.facetFilters;
  state.facetFilters = { categories, subcategories };
  state.numericFilters = {};
  state.page = 0;
}

export function hasActiveFilters() {
  if (!emptyObject(state.numericFilters)) return true;
  return Object.keys(state.facetFilters).filter(facet => facet.match(/specifications/i)).length > 0;
}

export function numberOfPages() {
  return state.algoliaResponse?.nbPages || 0;
}

export function isFilterActive(filter: any) {
  return state.numericFilters?.[filter.name] === filter.option;
}

export function isFacetActive(facet: any) {
  return state.facetFilters?.[facet.name]?.includes?.(facet.option) || false;
}

function setQuery(query: string) {
  state.query = query;
  state.page = 0;
}

function formattedFilters() {
  console.log('state.facet');
  console.log(state.facetFilters);
  console.log('state.facet');
  return {
    facetFilters: Object.entries(state.facetFilters).map(
      ([key, value]) => value.map(filter => `${key}:${filter}`),
    ),
    numericFilters: Object.entries(state.numericFilters).map(
      ([key, filter]) => `${key}${filter}`,
    ),
  };
}
