
import { computed, defineComponent } from 'vue';
import NumericFilter from './Filters/Numeric.vue';
import TypeFilter from './Filters/Type.vue';
import {
  hasActiveFilters,
  useFacetsStats,
  clearFilters,
  useFacets,
} from '@/store/search';

export default defineComponent({
  name: 'EquipmentFilters',
  components: {
    TypeFilter,
    NumericFilter,
  },
  setup() {
    const facets = useFacets();
    const facets_stats = useFacetsStats();
    const facetStats = computed(() => Object.keys(facets_stats.value));
    const filters = computed(() => Object.entries(facets.value).filter(([key, value]) => (
      key.match(/specifications/i) && !key.match(/Peso/) && Object.keys(value).length > 1
    )));
    return { clearFilters, facets_stats, facetStats, filters, hasActiveFilters };
  },
});
