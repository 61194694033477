<template>
  <div class="my-3 text-gray-500 font-semibold text-lg">
    <span class="uppercase">{{ name.replace('specifications.', '') }}</span>
    <div class="flex items-center flex-wrap">
      <button
        v-for="(amount, option) in options"
        :key="option"
        class="text-sm block p-1 mx-4"
        :class="isFacetActive({ name, option }) ? 'text-primary' : 'text-secondary underline'"
        @click="toggleFacet({ name, option })"
      >
        {{ option }} ({{ amount }})
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isFacetActive, toggleFacet } from '../../../store/search';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { isFacetActive, toggleFacet };
  },
});
</script>

<style></style>
