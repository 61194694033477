
import { defineComponent } from 'vue';
import { isFacetActive, toggleFacet } from '../../../store/search';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { isFacetActive, toggleFacet };
  },
});
