<template>
  <ul
    class="w-full space-x-5 flex justify-center text-3xl items-center text-primary"
  >
    <li>
      <a href="#catalog" @click="moveToPage(page - 1)">
        <r-icon type="chevron-left" class="h-10" />
      </a>
    </li>
    <li
      v-for="pageNumber in numberOfPages()"
      :key="`page-button-${pageNumber}`"
      :class="{ 'text-secondary': page === pageNumber - 1 }"
      @click="moveToPage(pageNumber - 1)"
    >
      <a href="#catalog" class="focus:outline-none px-2">
        {{ pageNumber }}
      </a>
    </li>
    <li>
      <a href="#catalog" @click="moveToPage(page + 1)">
        <r-icon type="chevron-right" class="h-10" />
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  usePage,
  numberOfPages,
  changePage,
  filterEquipment,
} from '../store/search';

export default defineComponent({
  setup() {
    const page = usePage();
    return { page, numberOfPages };
  },
  methods: {
    moveToPage(page: number) {
      changePage(page);
      filterEquipment();
    },
  },
});
</script>

<style></style>
