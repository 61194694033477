<template>
  <div class="my-3 text-gray-500 font-semibold text-lg">
    <span class="uppercase">{{ name.replace("specifications.", "") }}</span>
    <template v-if="Object.keys(options).length > 3 && stats.max > stats.min">
      <div class="">
        <button
          class="text-sm block p-1 mx-4"
          :class="status('Filter', `<${Math.ceil(firstQuartile)}`)"
          @click="addFilter({ name, value: `<${Math.ceil(firstQuartile)}` })"
        >
          menor a {{ Math.ceil(firstQuartile) }}{{ unit }}
        </button>
        <button
          class="text-sm block p-1 mx-4"
          :class="
            status(
              'Filter',
              `:${Math.ceil(firstQuartile)} TO ${Math.floor(thirdQuartile)}`
            )
          "
          @click="
            addFilter({
              name,
              value: `:${Math.ceil(firstQuartile)} TO ${Math.floor(
                thirdQuartile
              )}`,
            })
          "
        >
          {{ Math.ceil(firstQuartile) }}{{ unit }} a
          {{ Math.floor(thirdQuartile) }}{{ unit }}
        </button>
        <button
          class="text-sm block p-1 mx-4"
          :class="status('Filter', `>${Math.ceil(firstQuartile)}`)"
          @click="addFilter({ name, value: `>${Math.ceil(firstQuartile)}` })"
        >
          {{ Math.floor(thirdQuartile) }}{{ unit }} o más
        </button>
      </div>
    </template>
    <template v-else>
      <div class="">
        <button
          v-for="(amount, option) in options"
          :key="option"
          class="text-sm block p-1 mx-4"
          :class="status('Facet', option)"
          @click="toggleFacet({ name, option })"
        >
          {{ option }} {{ unit }} ({{ amount }})
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { isFacetActive, isFilterActive, toggleFacet, applyFilter } from '../../../store/search';
import { Ref } from 'vue';

type AlgoliaFilter = 'Facet'|'Filter';

export default defineComponent({
  // eslint-disable-next-line vue/require-prop-types
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const sortedFacets: Ref<number[]> = ref([]);
    return { sortedFacets, isFacetActive, isFilterActive, toggleFacet };
  },
  computed: {
    unit(): string {
      return (this.units as any)[this.name];
    },
    units(): Object {
      return {
        'specifications.peso': 'kg',
        'specifications.voltaje': 'V',
        'specifications.potencia': 'kW',
        'specifications.ancho base': 'm',
        'specifications.altura de carga': 'm',
        'specifications.altura de torre': 'm',
        'specifications.largo contraido': 'm',
        'specifications.amperaje a 220 v': 'a',
        'specifications.altura de trabajo': 'm',
        'specifications.alcance horizontal': 'm',
        'specifications.capacidad de carga': 'kg',
        'specifications.capacidad nominal estipulada': 'kg',
        'specifications.altura de plataforma': 'm',
        'specifications.velocidad de desplazamiento': 'km/h',
        'specifications.capacidad de la bomba': 'l/min',
        'specifications.capacidad nominal': 'kg',
        'specifications.altura de mastil extendido': 'mm',
        'specifications.altura de mastil contraido': 'mm',
        'specifications.horquillas': 'cm',
        'specifications.centro de carga': 'mm',
        'specifications.cap. deposito combustible': 'l',
        'specifications.golpes por minuto': 'rpm',
        'specifications.fuerza de impacto': 'kn',
        'specifications.operativo': 'kg',
        'specifications.potencia max': 'kw',
        'specifications.carga de vuelco': 'kg',
      };
    },
    firstQuartile(): number {
      return this.quartile(this.sortedFacets, 0.25);
    },
    thirdQuartile(): number {
      return this.quartile(this.sortedFacets, 0.75);
    },
  },
  mounted() {
    this.sortedFacets = Object.keys(this.options)
      .map((val) => parseFloat(val))
      .sort((a, b) => a - b);
  },
  methods: {
    addFilter(filter: any) {
      applyFilter(filter);
    },
    status(type: AlgoliaFilter, value: string) {
      const isMethod = type === 'Facet'? this.isFacetActive : this.isFilterActive;
      return isMethod({ name: this.name, option: value })
        ? 'text-primary'
        : 'text-secondary underline';
    },
    quartile(data: number[], q: number): number {
      // we're assuming it's already sorted, above, vs. the function use here. same difference.
      const pos = (data.length - 1) * q; // i = (n - 1) * p
      const base = Math.floor(pos); // i0 = Math.floor(i)
      const rest = pos - base; // (i - i0);
      if (data[base + 1] !== undefined) {
        //      value0    + (i - i0)   * (value1 which is values[i0+1] - value0 which is values[i0])
        return data[base] + rest * (data[base + 1] - data[base]);
      }
      // I think this is covered by if (p <= 0 || n < 2)
      return data[base];
    },
  },
});
</script>

<style></style>
